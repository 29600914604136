<template>
  <div
    style="position: relative; height: calc(100vh - 50px); overflow-y: scroll"
  >
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div @click="handleBack" style="cursor: pointer">
      <i class="el-icon-arrow-left"></i>
      <span>返回订单列表</span>
    </div>

    <div class="add_line">
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="NFT封面图" prop="zlist">
          <div class="video_upload">
            <el-upload
              :action="UploadUrl()"
              ref="uploadimg"
              :limit="3"
              :auto-upload="false"
              :on-change="imageChange"
              :show-file-list="true"
              :file-list="[]"
              list-type="picture-card"
              :on-exceed="handleExceed"
              :class="{ disabled: uploadDisabled }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>

            <el-progress
              v-show="progressFlagImg"
              :percentage="loadProgressImg"
            ></el-progress>

            <!-- 拖拽组件 -->
            <draggable
              v-model="zlist"
              :animation="100"
              class="dira"
              @update="handleDraggable"
            >
              <div class="isblocks" v-for="(item, index) in zlist" :key="index">
                <div class="ress">
                  <el-image
                    ref="preview"
                    style="width: 100px; height: 100px"
                    :preview-src-list="zlist"
                    :src="item"
                  ></el-image>
                  <div class="imgs_prews">
                    <div class="imgs_prew" style="width: 100px; height: 100px">
                      <i @click="ylimg(index)" class="el-icon-view"></i>
                      <span></span>
                      <i @click="deleteimg(index)" class="el-icon-delete"></i>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
        </el-form-item>
        <el-form-item label="NFT艺术家" prop="nftArtist">
          <el-input
            placeholder="请输入NFT艺术家"
            v-model="form.nftArtist"
          ></el-input>
        </el-form-item>
        <el-form-item label="购买渠道" prop="transChannel">
          <el-input
            placeholder="请输入购买渠道"
            v-model="form.transChannel"
          ></el-input>
        </el-form-item>
        <el-form-item label="合约地址" prop="nftContract">
          <el-input
            placeholder="请输入合约地址"
            v-model="form.nftContract"
          ></el-input>
        </el-form-item>
        <el-form-item label="管理员1" prop="uid1">
          <el-button
            type="primary"
            @click="dialogAdmin1 = true"
            style="margin-right: 10px"
            >请选择管理员1</el-button
          >
          <span v-if="form.uid1">{{ admin1Data.phone }}</span>

          <el-dialog title="请选择管理员1" :visible.sync="dialogAdmin1">
            <div style="display: flex">
              <el-select
                v-model="valueArea1"
                filterable
                placeholder="请选择区号(可搜索关键字)"
                style="width: 240px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.name"
                  :value="item.tel"
                >
                  <div style="display: flex; align-items: center">
                    {{ item.tel }} {{ item.name }}
                  </div>
                </el-option>
              </el-select>
              <el-input
                placeholder="请输入管理员1"
                v-model="form.uid1"
                style="margin: 0 10px; width: 300px"
                :disabled="valueArea1 == ''"
              ></el-input>
              <el-button type="primary" @click="handleSelectAdmin(1)"
                >搜索管理员</el-button
              >
            </div>

            <el-card class="box-card">
              <div>用户名：{{ admin1Data.userName }}</div>
              <div>真实姓名：{{ admin1Data.realName }}</div>
              <div>手机号：{{ admin1Data.phone }}</div>
            </el-card>

            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogAdmin1 = false">取 消</el-button>
              <el-button type="primary" @click="dialogAdmin1 = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </el-form-item>

        <el-form-item label="管理员2" prop="uid2">
          <el-button
            type="primary"
            @click="dialogAdmin2 = true"
            style="margin-right: 10px"
            >请选择管理员2</el-button
          >
          <span v-if="form.uid2">{{ admin2Data.phone }}</span>

          <el-dialog title="请选择管理员2" :visible.sync="dialogAdmin2">
            <div style="display: flex">
              <el-select
                v-model="valueArea2"
                filterable
                placeholder="请选择区号(可搜索关键字)"
                style="width: 240px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.name"
                  :value="item.tel"
                >
                  <div style="display: flex; align-items: center">
                    {{ item.tel }} {{ item.name }}
                  </div>
                </el-option>
              </el-select>
              <el-input
                placeholder="请输入管理员2"
                v-model="form.uid2"
                style="margin: 0 10px; width: 300px"
                :disabled="valueArea2 == ''"
              ></el-input>
              <el-button type="primary" @click="handleSelectAdmin(2)"
                >搜索管理员</el-button
              >
            </div>

            <el-card class="box-card">
              <div>用户名：{{ admin2Data.userName }}</div>
              <div>真实姓名：{{ admin2Data.realName }}</div>
              <div>手机号：{{ admin2Data.phone }}</div>
            </el-card>

            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogAdmin2 = false">取 消</el-button>
              <el-button type="primary" @click="dialogAdmin2 = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </el-form-item>

        <el-form-item label="管理员3" prop="uid3">
          <el-button
            type="primary"
            @click="dialogAdmin3 = true"
            style="margin-right: 10px"
            >请选择管理员3</el-button
          >
          <span v-if="form.uid2">{{ admin3Data.phone }}</span>

          <el-dialog title="请选择管理员3" :visible.sync="dialogAdmin3">
            <div style="display: flex">
              <el-select
                v-model="valueArea3"
                filterable
                placeholder="请选择区号(可搜索关键字)"
                style="width: 240px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.name"
                  :value="item.tel"
                >
                  <div style="display: flex; align-items: center">
                    {{ item.tel }} {{ item.name }}
                  </div>
                </el-option>
              </el-select>
              <el-input
                placeholder="请输入管理员3"
                v-model="form.uid3"
                style="margin: 0 10px; width: 300px"
                :disabled="valueArea3 == ''"
              ></el-input>
              <el-button type="primary" @click="handleSelectAdmin(3)"
                >搜索管理员</el-button
              >
            </div>

            <el-card class="box-card">
              <div>用户名：{{ admin3Data.userName }}</div>
              <div>真实姓名：{{ admin3Data.realName }}</div>
              <div>手机号：{{ admin3Data.phone }}</div>
            </el-card>

            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogAdmin3 = false">取 消</el-button>
              <el-button type="primary" @click="dialogAdmin3 = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </el-form-item>

        <div class="line_footer">
          <el-button round type="primary" @click="submitForm('form')"
            >提 交</el-button
          >
          <el-button round @click="resetForm('form')">重 置</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Breadcrumb from "@/components/BreadCrumb";

import { areaCodeData } from "@/util/areaCodeData";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import draggable from "vuedraggable";

export default {
  components: { Breadcrumb, draggable },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "线路管理",
        },
        {
          name: "新建路线",
        },
      ], // 面包屑数据

      rules: {
        startCityName: [
          { required: true, message: "请选择起点城市", trigger: "blur" },
        ],
        endCityName: [
          { required: true, message: "请选择终点城市", trigger: "blur" },
        ],
      },
      cityType: "",
      form: {
        nftArtist: "",
        transChannel: "",
        nftContract: "",
        uid1: "",
        uid2: "",
        uid3: "",
      },
      valueArea1: "",
      valueArea2: "",
      valueArea3: "",
      options: [],

      showInput: false,

      fileList: [],
      fileListImg: [],
      uploadDisabled: false,
      progressFlagImg: false,
      loadProgressImg: 0,
      zlist: [], //组件数组

      dialogAdmin1: false,
      dialogAdmin2: false,
      dialogAdmin3: false,
      admin1Data: {},
      admin2Data: {},
      admin3Data: {},

      lineData: {},
    };
  },

  watch: {
    fileListImg(newvalue) {
      this.fileListImg = newvalue;
    },
  },

  created() {
    //回显数组
    this.zlist = this.fileListImg;
  },

  mounted() {
    this.lineData = JSON.parse(localStorage.getItem("lineRow"));
    this.form = this.lineData;

    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");
  },

  methods: {
    handleBack() {
      this.$router.push("/worldLine");
    },

    handleEdit() {
      this.showInput = true;
    },

    UploadUrl() {
      return reqUrl.imageUpload;
    },

    //选择图片后做图片格式限制（手动上传图片时，before-upload钩子无效，使用此方法替代）
    imageChange(file, fileList) {
      this.progressFlagImg = true;
      this.loadProgressImg = 0;
      const interval = setInterval(() => {
        if (this.loadProgressImg >= 99) {
          clearInterval(interval);
          return;
        }
        this.loadProgressImg += 1;
      }, 20);

      if (fileList.length >= 3) {
        this.uploadDisabled = true;
      }
      axios.get(file.url, { responseType: "blob" }).then((res) => {
        const formData = new FormData();
        formData.append("file", res.data);
        formData.append("userId", 1);
        formData.append("scene", 2);

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: reqUrl.imageUpload,
          header: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          this.zlist.push(res.data.data.url);

          this.progressFlagImg = false;
          this.loadProgress = 100;
        });
      });

      const isImage =
        file.raw.type == "image/png" ||
        file.raw.type == "image/jpg" ||
        file.raw.type == "image/jpeg";
      const isLt5M = file.size < 1024 * 1024 * 10;
      if (!isImage) {
        this.$message.error("上传只能是png,jpg,jpeg格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }

      if (isImage && isLt5M) {
        this.uploadFile = file.raw || null;
      } else {
        fileList.splice(-1, 1);
      }
    },

    //超出限制个数钩子
    handleExceed(files) {
      this.$message.warning("当前限制选择12个文件，请删除图片后重新添加");
    },

    handleDraggable(event) {
      const { oldIndex, newIndex } = event;

      this.fileListImg.splice(
        newIndex,
        0,
        this.fileListImg.splice(oldIndex, 1)[0]
      );
    },

    //删除
    deleteimg(index) {
      this.$delete(this.zlist, index);
    },
    //预览
    ylimg(index) {
      this.$refs.preview[index].showViewer = true;
    },

    handleSelectAdmin(type) {
      const optUser = {
        url: reqUrl.travelRegionGetUser,
        method: "POST",
        params: JSON.stringify({
          phone:
            type == 1
              ? this.form.uid1
              : type == 2
              ? this.form.uid2
              : type == 3
              ? this.form.uid3
              : "",
          phoneArea:
            type == 1
              ? this.valueArea1
              : type == 2
              ? this.valueArea2
              : type == 3
              ? this.valueArea3
              : "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            if (type == 1) {
              this.admin1Data = "";
            }
            if (type == 2) {
              this.admin2Data = "";
            }
            if (type == 3) {
              this.admin3Data = "";
            }
            hideLoading();
          } else if (res.data.code == 0) {
            if (type == 1) {
              this.admin1Data = res.data.data;
            }
            if (type == 2) {
              this.admin2Data = res.data.data;
            }
            if (type == 3) {
              this.admin3Data = res.data.data;
            }
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(optUser);
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("请确认信息填写是否正确，确认请继续", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              showLoading();
              const opt = {
                url: reqUrl.travelRegionUpdateFixedTravel,
                method: "POST",
                params: JSON.stringify({
                  id: this.lineData.id,
                  uid1: this.admin1Data.userId,
                  uid2: this.admin2Data.userId,
                  uid3: this.admin3Data.userId,
                  nftContract: this.form.nftContract,
                  nftUrl1: this.zlist[0],
                  nftUrl2: this.zlist[1],
                  nftUrl3: this.zlist[2],
                  nftArtist: this.form.nftArtist,
                  transChannel: this.form.transChannel,
                }),
                resFunc: (res) => {
                  console.log(res.data);
                  if (res.data.code != 0) {
                    this.$notify.error({
                      title: "错误",
                      message: res.data.message,
                    });
                    hideLoading();
                  } else if (res.data.code == 0) {
                    this.$message.success("修改路线成功");
                    this.$router.push("/worldLine");
                    hideLoading();
                  }
                  hideLoading();
                },
                errFunc: (err) => {
                  this.$notify.error({
                    title: "错误",
                    message: err,
                  });
                  hideLoading();
                },
              };
              request(opt);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },

  destroyed() {
    localStorage.removeItem("lineRow");
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.add_line {
  border-radius: 10px;
  background: #fff;
  padding: 20px 0;
  margin-top: 10px;

  .el-cascader {
    width: 300px;
  }

  .el-form {
    .el-table {
      width: 105%;
      max-width: 105%;
      margin-left: -80px;
      margin-top: 10px;
      .el-input {
        width: 100px;
      }
    }

    .el-card {
      margin-top: 10px;
    }
  }
}

.line_footer {
  display: flex;
  justify-content: center;

  .el-button {
    width: 200px;
  }
}
</style>
